import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";

function ImageForm({ number }) {
  const [files, setFiles] = useState([]);
  const [firstname, setFirstName] = useState("");
  const [middlename, setMiddleName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [businessname, setBusinessName] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [pincode, setPincode] = useState("");
  const [previewUrls, setPreviewUrls] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(""); // Error message state
  
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Ensure no more than 5 files are selected in total
    if (selectedFiles.length + files.length > 5) {
      alert("You can upload a maximum of 5 images.");
      return;
    }

    const newFiles = [...files, ...selectedFiles.slice(0, 5 - files.length)];
    setFiles(newFiles);

    const selectedFilesPreview = newFiles.map((file) => URL.createObjectURL(file));
    setPreviewUrls(selectedFilesPreview);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous error messages
    setError("");

    // Validation for required fields
    if (!firstname || !lastname || !email || !businessname || !description || !location || !pincode) {
      alert("Please fill in all fields.");
      return;
    }

    // Simple email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Simple pincode validation (assuming pincode is 6 digits)
    if (!/^\d{6}$/.test(pincode)) {
      alert("Please enter a valid 6-digit pincode.");
      return;
    }

    // FormData setup
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file);
    });
    formData.append("firstname", firstname);
    formData.append("middlename", middlename);
    formData.append("lastname", lastname);
    formData.append("email", email);
    formData.append("number", number);
    formData.append("businessname", businessname);
    formData.append("description", description);
    formData.append("location", location);
    formData.append("pincode", pincode);

    setLoading(true); // Start loading
    try {
      await axios.post("https://nagpurdial.com/api/fileUpload/freelisting", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Files uploaded successfully");
      navigate("/"); // Redirect to home page after success
      // Reset the form
      setFiles([]);
      setFirstName("");
      setMiddleName("");
      setLastName("");
      setEmail("");
      setBusinessName("");
      setDescription("");
      setLocation("");
      setPincode("");
      setPreviewUrls([]);
    } catch (error) {
      console.error("Error uploading files:", error);
      setError("Failed to upload files, please try again.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="container">
      <div className="container-fluid p-10">
        <div className="file-upload-container">
          <form className="file-upload-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="firstname" className="form-label">First Name:</label>
                <input
                  type="text"
                  placeholder="Enter First name"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="name-input form-control"
                />
              </div>
              <div className="col-lg-12">
                <label htmlFor="middlename" className="form-label">Middle Name:</label>
                <input
                  type="text"
                  placeholder="Enter Middle name"
                  value={middlename}
                  onChange={(e) => setMiddleName(e.target.value)}
                  className="name-input form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="lastname" className="form-label">Last Name:</label>
                <input
                  type="text"
                  placeholder="Enter Last name"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                  className="name-input form-control"
                />
              </div>
              <div className="col-lg-12">
                <label htmlFor="businessname" className="form-label">Business Name:</label>
                <input
                  type="text"
                  placeholder="Enter business name"
                  value={businessname}
                  onChange={(e) => setBusinessName(e.target.value)}
                  className="name-input form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="location" className="form-label">Address:</label>
                <input
                  type="text"
                  placeholder="Enter business location"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  className="location-input form-control"
                />
              </div>
              <div className="col-lg-12">
                <label htmlFor="description" className="form-label">Business Description:</label>
                <textarea
                  placeholder="Enter business description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="description-input form-control"
                ></textarea>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="email" className="form-label">Email:</label>
                <input
                  type="email"
                  placeholder="Enter business email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="email-input form-control"
                />
              </div>
              <div className="col-lg-12">
                <label htmlFor="pincode" className="form-label">Pincode:</label>
                <input
                  type="text"
                  placeholder="Enter pincode"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  className="pincode-input form-control"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <label htmlFor="file" className="form-label">Choose Images (Max 5):</label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="file-input form-control"
                  multiple
                />
              </div>
              <div className="col-lg-12">
                <button type="submit" className="upload-button btn btn-success mt-3" disabled={loading}>
                  {loading ? "Uploading..." : "Save"}
                </button>
              </div>
            </div>
          </form>

          {error && <p className="text-danger">{error}</p>}

          {previewUrls.length > 0 && (
            <div className="image-preview mt-3">
              {previewUrls.map((url, index) => (
                <img
                  key={index}
                  src={url}
                  alt={`Preview ${index + 1}`}
                  style={{ maxWidth: "200px", maxHeight: "150px", marginRight: "10px" }}
                  className="preview-image"
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

ImageForm.propTypes = {
  number: PropTypes.string.isRequired,
};

export default ImageForm;